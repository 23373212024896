import { ReactNode } from 'react';
// import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { isNullish } from '@/utils/boolean';
import { isEmpty } from '@/utils/arrays';
import {
  classes,
  SelectDropdownChangeEvent,
  SelectDropdownOption,
  styles,
} from './utils';

export type { SelectDropdownOption, SelectDropdownChangeEvent };

export interface SelectDropdownProps<
  T extends string | number,
  N extends string,
> {
  disabled?: boolean;
  className?: string;
  name: N;
  label?: string;
  placeholder?: string;
  value: SelectDropdownOption<T> | null | undefined;
  options?: Array<SelectDropdownOption<T>>;
  renderIcon?: (className: string) => ReactNode;
  onChange: (update: SelectDropdownChangeEvent<T, N>) => void;
}

export default function SelectDropdown<
  T extends string | number,
  N extends string,
>({
  disabled,
  className = '',
  options = [],
  name,
  label,
  placeholder,
  value,
  renderIcon,
  onChange,
}: SelectDropdownProps<T, N>) {
  // TODO: Add support for selecting multiple values

  const handleChange = ({ target }: SelectChangeEvent<T>) => {
    const option = options.find((o) => o.value === target.value)!;
    onChange({ [name]: option } as Record<N, { value: T; label: string }>);
  };

  const getOption = (value: T) => {
    return options.find((o) => o.value === value)!;
  };

  const getLabels = (selected: T) => {
    if (isNullish(selected)) return [];
    return [getOption(selected).label];
  };

  const getValue = () => {
    if (isNullish(value)) return '';
    else return value.value;
  };

  const renderValue = (selected: T) => {
    const labels = getLabels(selected);
    if (isEmpty(labels)) return '';
    return labels[0];
  };

  return (
    <FormControl disabled={disabled} className={className} size="small">
      {label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
      <Select<T>
        labelId={`${name}-label`}
        id={name}
        name={name}
        placeholder={placeholder}
        value={getValue()}
        onChange={handleChange}
        input={
          <OutlinedInput
            id={`${name}-input`}
            label={label}
            startAdornment={
              value &&
              renderIcon && (
                <InputAdornment position="start">
                  {renderIcon(classes.inputIcon)}
                </InputAdornment>
              )
            }
          />
        }
        renderValue={renderValue}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            sx={styles.menuItem}
            className={classes.menuItem}
            value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
