import { API } from '@/api';
import { buildUserOption } from '@/utils/filters';
import { type SearchableDropdownOption } from '@/components/SearchableDropdown';
import { isBlank } from '@/utils/strings';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  userInput: 'w-full my-4',
  name: 'w-full my-4',
  description: 'w-full mt-4 mb-8',
};

export function validateFields({
  name,
  userID,
}: {
  name: string;
  userID?: number;
}) {
  let invalidFields = [];
  if (isBlank(name) && !userID) invalidFields.push('name');
  return invalidFields;
}

export function createUnisonProject(data: {
  name: string;
  description: string;
  userID?: number;
}) {
  return API.UnisonProjects.createNewProject(data);
}

export function updateUnisonProject(
  projectID: number,
  data: { name: string; description: string; userID?: number }
) {
  return API.UnisonProjects.updateUnisonProject(projectID, data);
}

export async function searchLeads(searchQuery: string) {
  const search = searchQuery.toLowerCase().trim();
  let leadSuggestions: SearchableDropdownOption<number>[] = [];
  if (search.length > 0) {
    const leads = await API.Leads.fetchMatchingLeads({
      search,
      fields: ['firstname', 'lastname'],
      properties: [],
      filters: [],
      pagination: { page: 1, pageSize: 25 },
    });
    leadSuggestions = leads.results.map(buildUserOption);
  }
  return leadSuggestions;
}
