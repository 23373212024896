import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import type { DecoratedLead, UndecoratedLead } from '@witmetrics/api-client';

type LeadsState = {
  byID: Record<string, UndecoratedLead>;
};

const initialState = {
  byID: {},
} satisfies LeadsState as LeadsState;

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLeads: (state, action: PayloadAction<DecoratedLead[]>) => {
      return getNextState(state, action.payload);
    },
    addLead: (state, action: PayloadAction<DecoratedLead>) => {
      return getNextState(state, [action.payload]);
    },
    updateLead: (state, action: PayloadAction<DecoratedLead>) => {
      return getNextState(state, [action.payload]);
    },
    deleteLead: (state, action: PayloadAction<{ leadID: number }>) => {
      delete state.byID[`${action.payload.leadID}`];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {} };
      })
      .addDefaultCase(() => {});
  },
});

function formatLead(lead: DecoratedLead | UndecoratedLead) {
  const { userProperties, userPractices, treatmentPlans, ...l } =
    lead as DecoratedLead;
  return l;
}

function getNextState(
  state: LeadsState,
  leads: (DecoratedLead | UndecoratedLead)[]
) {
  let byID = { ...state.byID };
  leads.forEach((lead) => {
    byID[lead.id] = formatLead(lead);
  });
  return { byID };
}

export const { setLeads, addLead, updateLead, deleteLead } = leadsSlice.actions;

export default leadsSlice.reducer;
