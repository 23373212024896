import { theme } from '@/utils/theme';

export type SelectDropdownOption<T extends string | number> = {
  value: T;
  label: string;
};

export type SelectDropdownChangeEvent<
  T extends string | number,
  N extends string,
> = Record<N, SelectDropdownOption<T>>;

export const classes = {
  menuItem:
    'transition-colors duration-100 text-grey-8 font-normal py-2 m-2 rounded-lg',
  itemContent: 'flex flex-col items-start justify-start',
  contentDescription: 'text-sm text-grey-6',
  inputIcon: 'text-xl text-grey-7',
};

export const styles = {
  box: { display: 'flex', flexWrap: 'wrap', gap: 0.5 },
  menuItem: {
    '&': {
      backgroundColor: theme.palette['white'].main,
    },
    '&:hover, &.Mui-selected, &.Mui-selected:hover, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible':
      {
        backgroundColor: theme.palette['grey-2'].main,
      },
  },
};
